@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@layer base {
    body {
        @apply font-[Vollkorn];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: radial-gradient(100% 100% at 0% 0%, #1D44AB 0%, #0B0018 100%)
    !important;
}